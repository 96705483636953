table {
    margin-top: 10px;
}

table > thead {
    background-color:#E7EDF6;
}

table > thead > th {
    padding: 10px;
}