.keterangan {
    text-align: center;
}

.icon-empty {
    margin-bottom: 2rem;
    margin-top: 150px;
    width: 100%;
    height: 226px;
    background-image: url('assets/images/events/tanda-seru.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.info-empty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #0D47A1;
    margin-bottom: 10px;
}

.inspect {
    color: #545454;
}